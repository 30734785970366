var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var setupNetworkIdTimer = function (address, // Address / string
provider, // ProviderRpcClient
allowedNetworkIds) {
    if (window && address && window.venomNetworkIntervalId) {
        resetNetworkIdTimer();
    }
    if (provider && address && window && !window.venomNetworkIntervalId) {
        window.venomNetworkIntervalId = window.setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
            var state, isAllowedId, isAuth;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, ((_a = provider === null || provider === void 0 ? void 0 : provider.getProviderState) === null || _a === void 0 ? void 0 : _a.call(provider))];
                    case 1:
                        state = _d.sent();
                        isAllowedId = true;
                        if (allowedNetworkIds !== undefined) {
                            if (Array.isArray(allowedNetworkIds)) {
                                isAllowedId = !!allowedNetworkIds.find(function (id) { return state.networkId === id; });
                            }
                            else {
                                isAllowedId = state.networkId === allowedNetworkIds;
                            }
                        }
                        isAuth = !!((_c = (_b = state === null || state === void 0 ? void 0 : state.permissions) === null || _b === void 0 ? void 0 : _b.accountInteraction) === null || _c === void 0 ? void 0 : _c.address);
                        // console.log('TESING NET', allowedNetworkIds, state)
                        // console.log('E SET TO WRONG', state && address && !isAllowedId)
                        window.updateVenomModal({
                            wrongNetwork: isAuth ? state && address && !isAllowedId : undefined,
                        });
                        return [2 /*return*/];
                }
            });
        }); }, 1000);
    }
};
export var resetNetworkIdTimer = function () {
    clearInterval(window.venomNetworkIntervalId);
    window.venomNetworkIntervalId = undefined;
};
