var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { toggleExtensionWindow } from "../../helpers/backdrop";
import { getKey as getKeyRaw, log, makeMove } from "../../helpers/utils";
import { setupNetworkIdTimer } from "./networkIdTimerUtil";
export var oxychatWalletName = "Oxychat Wallet";
var getKey = function (type) { return getKeyRaw(oxychatWalletName, type); };
/**
 * oxychatProvider: ProviderRpcClient
 */
export var checkIsProviderExist = function (oxychatProvider) { return __awaiter(void 0, void 0, void 0, function () {
    var isExist, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, (oxychatProvider === null || oxychatProvider === void 0 ? void 0 : oxychatProvider.hasProvider())];
            case 1:
                isExist = !!(_a.sent());
                if (!isExist) {
                    log({
                        type: "error",
                        key: oxychatWalletName,
                        value: "Extension is not installed",
                    });
                }
                return [2 /*return*/, isExist];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * oxychatProvider: ProviderRpcClient,
 * options: any | undefined
 */
var checkOxychatWalletAuth = function (OxychatProvider, options) { return __awaiter(void 0, void 0, void 0, function () {
    var key, oxychatProvider_1, auth, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                key = getKey("extension/auth");
                log({
                    key: key,
                    value: "check auth start",
                });
                return [4 /*yield*/, makeMove({
                        before: "provider creating",
                        after: "provider created",
                        error: "provider creating failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, new OxychatProvider(options)];
                        });
                    }); })];
            case 1:
                oxychatProvider_1 = _a.sent();
                return [4 /*yield*/, makeMove({
                        before: "injected provider checking",
                        after: "provider injected",
                        error: "injected provider checking failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var isExist;
                        return __generator(this, function (_a) {
                            isExist = checkIsProviderExist(oxychatProvider_1);
                            if (!isExist)
                                throw new Error();
                            return [2 /*return*/];
                        });
                    }); })];
            case 2:
                _a.sent();
                return [4 /*yield*/, makeMove({
                        before: "auth checking",
                        after: "auth checked",
                        error: "auth checking failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var getProviderState, permissions, accountInteraction, address;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, ((_a = oxychatProvider_1 === null || oxychatProvider_1 === void 0 ? void 0 : oxychatProvider_1.getProviderState) === null || _a === void 0 ? void 0 : _a.call(oxychatProvider_1))];
                                case 1:
                                    getProviderState = _b.sent();
                                    permissions = getProviderState === null || getProviderState === void 0 ? void 0 : getProviderState.permissions;
                                    accountInteraction = permissions === null || permissions === void 0 ? void 0 : permissions.accountInteraction;
                                    address = accountInteraction === null || accountInteraction === void 0 ? void 0 : accountInteraction.address;
                                    // здесь вызовется только когда уже был залогинен
                                    setupNetworkIdTimer(address, oxychatProvider_1, options.checkNetworkId);
                                    return [2 /*return*/, address && oxychatProvider_1];
                            }
                        });
                    }); })];
            case 3:
                auth = _a.sent();
                log({
                    key: key,
                    value: "check auth end",
                });
                return [2 /*return*/, {
                        auth: auth,
                        fallback: oxychatProvider_1,
                    }];
            case 4:
                error_2 = _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
/**
 * oxychatProvider: ProviderRpcClient,
 * options: any | undefined
 * callbacks: Callbacks
 */
var connectToOxychatWallet = function (OxychatProvider, options, callbacks) { return __awaiter(void 0, void 0, void 0, function () {
    var key, oxychatProvider_2, permissions_1, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                key = getKey("extension");
                log({
                    key: key,
                    value: "connection start",
                });
                return [4 /*yield*/, makeMove({
                        before: "provider creating",
                        after: "provider created",
                        error: "provider creating failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, new OxychatProvider(options)];
                        });
                    }); })];
            case 1:
                oxychatProvider_2 = _a.sent();
                return [4 /*yield*/, makeMove({
                        before: "injected provider checking",
                        after: "provider injected",
                        error: "injected provider checking failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var isExist;
                        return __generator(this, function (_a) {
                            isExist = checkIsProviderExist(oxychatProvider_2);
                            if (!isExist)
                                throw new Error();
                            return [2 /*return*/];
                        });
                    }); })];
            case 2:
                _a.sent();
                permissions_1 = ["basic", "accountInteraction"];
                return [4 /*yield*/, toggleExtensionWindow({
                        isExtensionWindowOpen: true,
                    })];
            case 3:
                _a.sent();
                return [4 /*yield*/, makeMove({
                        before: "permissions requesting (".concat(permissions_1.join(", "), ")"),
                        after: "permissions requested",
                        error: "permissions requesting failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var accountInteraction;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, (oxychatProvider_2 === null || oxychatProvider_2 === void 0 ? void 0 : oxychatProvider_2.requestPermissions({
                                        permissions: permissions_1,
                                    }))];
                                case 1:
                                    accountInteraction = (_a.sent()).accountInteraction;
                                    if (accountInteraction == null) {
                                        throw new Error("Insufficient permissions");
                                    }
                                    setupNetworkIdTimer(accountInteraction.address, oxychatProvider_2, options.checkNetworkId);
                                    return [2 /*return*/, accountInteraction];
                            }
                        });
                    }); })];
            case 4:
                _a.sent();
                log({
                    key: key,
                    value: "connection end",
                });
                callbacks.authorizationCompleted(oxychatProvider_2);
                return [4 /*yield*/, toggleExtensionWindow({
                        isExtensionWindowOpen: false,
                    })];
            case 5:
                _a.sent();
                return [2 /*return*/, oxychatProvider_2];
            case 6:
                error_3 = _a.sent();
                // console.error(error);
                callbacks.extensionWindowClosed();
                return [3 /*break*/, 7];
            case 7: return [4 /*yield*/, toggleExtensionWindow({
                    isExtensionWindowOpen: false,
                })];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
/**
 * oxychatProvider: ProviderRpcClient,
 * options: any | undefined
 */
var getStandaloneConnectionToOxychatWallet = function (OxychatProvider, options) { return __awaiter(void 0, void 0, void 0, function () {
    var key, oxychatProvider, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                key = getKey("extension");
                log({
                    key: key,
                    value: "standalone start",
                });
                return [4 /*yield*/, makeMove({
                        before: "standalone provider creating",
                        after: "standalone provider created",
                        error: "standalone provider creating failed",
                        key: key,
                    }, function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, new OxychatProvider(options)];
                        });
                    }); })];
            case 1:
                oxychatProvider = _a.sent();
                log({
                    key: key,
                    value: "standalone end",
                });
                return [2 /*return*/, oxychatProvider];
            case 2:
                error_4 = _a.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
var goByQRCode = function () {
    try {
        var key = getKey("qr");
        log({
            key: key,
            value: "work in progress",
        });
        return undefined;
    }
    catch (error) { }
};
var goByDeepLinkIOS = function () {
    try {
        var key = getKey("ios");
        log({
            key: key,
            value: "work in progress",
        });
        return undefined;
    }
    catch (error) { }
};
var goByDeepLinkAndroid = function () {
    try {
        var key = getKey("android");
        log({
            key: key,
            value: "work in progress",
        });
        return undefined;
    }
    catch (error) { }
};
var oxychatWallet = {
    extension: {
        connector: connectToOxychatWallet,
        authChecker: checkOxychatWalletAuth,
        standalone: getStandaloneConnectionToOxychatWallet,
    },
    mobile: {
        connector: goByQRCode,
    },
    ios: {
        connector: goByDeepLinkIOS,
    },
    android: {
        connector: goByDeepLinkAndroid,
    },
};
export default oxychatWallet;
